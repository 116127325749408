import React from 'react';

function Palette({colors}) {
  return (
    <div className="flex flex-wrap">
      {Object.entries(colors).map(([paletteName, palette]) => (
        <div className="w-full lg:w-1/3 p-2" key={paletteName}>
          <div className="bg-slate-100 p-4 rounded">
            <h3 className="text-lg mb-2 font-bold text-slate-800">{paletteName}</h3>
            <div className="grid grid-cols-3 gap-2">
              {Object.entries(palette).map(([colorName, color]) => {
                let className = colorName.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
                return (<div key={colorName}>
                  <div className={`🔥 bg-${paletteName}-${className} h-12 inner-shadow`}>
                  </div>
                  <div className="text-center mt-2">
                    <p className="text-xs font-medium">{className}</p>
                    <p className="text-xs">{color.value}</p>
                  </div>
                </div>)
              })}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Palette;
