import React, { useState, useEffect } from 'react';
import Highlight from 'react-highlight';

const Canvas = ({ component, children, inv, color }) => {
    if (component === 'summary') {
        return (
            <details className='w-96 h-44'>
                {children}
                <div className='p-4 text-left'>
                    <h2 className={`mb-4 h2 text-${color}${ inv === ' 🔄' ? `-contrast` : `-neutral` }`}>Hello!</h2>
                    <p>I was hiding. That's how these things work. This example uses the native HTML <code>&lt;details&gt;</code> and <code>&lt;summary&gt;</code>, but you don't have to.</p>
                </div>
            </details>
        );
    } else {
        return children;
    }
}


const SimpleVariants = ({ metaData }) => {
    const voidElements = ['input', 'br', 'hr', 'img', 'area', 'base', 'col', 'embed', 'param', 'source', 'track', 'wbr'];

    function unminifyHTML(html) {
        //eslint-disable-next-line
        const regEx = /(<[^\/][^>]*>)|(<\/[^>]*>)/g;
        let indentLevel = 0;
        let result = html.replace(regEx, (match) => {
            if (match.startsWith('</')) {
                indentLevel--;
                return '\n' + '  '.repeat(indentLevel) + match;
            } else {
                const result = '\n' + '  '.repeat(indentLevel) + match;
                indentLevel++;
                return result;
            }
        });
        return result;
    }


    function componentContent(str) {
        switch (str) {
            case 'select':
                return <><option>Option 1</option><option>Option 2</option><option>Option 3</option><option>Option 4</option></>;
            default:
                return metaData.displayName;
        }
    }

    function componentContentStr(str) {
        switch (str) {
            case 'select':
                return '<option>Option 1</option><option>Option 2</option><option>Option 3</option><option>Option 4</option>';
            default:
                return metaData.displayName;
        }
    }

    function isNotVoidElement(element) {
        return !voidElements.includes(element);
    }

    const [color, setColor] = useState('neutral-dark');
    const [style, setStyle] = useState(metaData.styles[0]);
    const [size, setSize] = useState('medium');
    const [inv, setInv] = useState(' 🔄');

    useEffect(() => {
        // Set the state values to their default values
        setColor(metaData.colors[0]);
        setStyle(metaData.styles[0]);
        setSize('default');
        setInv('');
    }, [metaData]);

    const handleColorChange = (event) => {
        setColor(event.target.value);
    };

    const handleStyleChange = (event) => {
        setStyle(event.target.value);
    };

    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };

    const handleFliperoo = () => {
        if (inv === ' 🔄') {
            setInv('');
        } else {
            setInv(' 🔄');
        }
    };

    let interactive = '';
    if (metaData?.states?.includes('hover')) {
        interactive = ' ✋';
    }

    const componentDemoContent = componentContentStr(metaData.element);

    return (
        <div className={``}>
            <div className='flex flex-col lg:flex-row'>
                <div className={`🔥 bg-${color}${ inv === ' 🔄' ? `-main` : `-contrast` } text-${color}${ inv === ' 🔄' ? `-contrast` : `-neutral` } text-center rounded-md h-36 lg:h-60 flex items-center justify-center lg:w-4/5 border border-primary-main`}>
                    <Canvas component={metaData.element} inv={inv} color={color}>
                    {
  React.createElement(
    metaData.element,
    {
      ...metaData.elementAttributes,
      className: `🔥 🧩:${size !== 'default' ? `${metaData.cssName} 📐:${size}` : `${metaData.cssName}`} 🎨:${color} 👀:${style}${interactive}${inv}`,
    },
    isNotVoidElement(metaData.element) ? componentContent(metaData.element) : null
  )
}

                    </Canvas>
                </div>
                <div className='lg:w-1/5 py-4 lg:py-0 lg:px-6'>
                <div className=''>
                <label htmlFor="color-select" className='block text-slate-800 text-sm font-bold mb-2'>Color</label>
                <div className="flex items-center">
                    <select
                        id="color-select"
                        value={color}
                        onChange={handleColorChange}
                        className="🔥 🧩:select 🎨:neutral-dark 👀:form-highlight ✋ mr-2">
                        {metaData.colors.map((colorOption) => (
                            <option key={colorOption} value={colorOption}>
                                {colorOption}
                            </option>
                        ))}
                    </select>
                    <button className='reset' onClick={handleFliperoo}>🔄</button>
                </div>
            </div>
            <div className='my-4'>
                <label htmlFor="style-select" className='block text-slate-800 text-sm font-bold mb-2'>Style</label>
                <select
                    id="style-select"
                    value={style}
                    onChange={handleStyleChange}
                    className="🧩:select 🎨:neutral-dark 👀:form-highlight ✋"
                >
                    {metaData.styles.map((styleOption) => (
                        <option key={styleOption} value={styleOption}>
                            {styleOption}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label htmlFor="size-select" className='block text-slate-800 text-sm font-bold mb-2'>Size</label>
                <select
                    id="size-select"
                    value={size}
                    onChange={handleSizeChange}
                    className="🧩:select 🎨:neutral-dark 👀:form-highlight ✋ "
                >
                    <option key="default" value="default">
                        Default
                    </option>
                    {metaData.sizes.map((sizeOption) => (
                        <option key={sizeOption} value={sizeOption}>
                            {sizeOption}
                        </option>
                    ))}
                </select>
            </div>
                </div>
            </div>
            <div className='hljs w-full p-4 my-4 rounded-md hidden lg:block'>
                <Highlight className='html'>
                    {`<${metaData.element} class="🧩:${size !== 'default' ? `${metaData.cssName} 📐:${size}` : `${metaData.cssName}`} 🎨:${color} 👀:${style}${interactive}${inv}"` + (isNotVoidElement(metaData.element) ? `>${unminifyHTML(componentDemoContent)}</${metaData.element}>` : `/>`)}
                </Highlight>

            </div>

        </div>
    );
};
export default SimpleVariants;

