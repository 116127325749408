import './App.css';
import { useData } from './utilities/data';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Simple from './components/components/simple';
import Layout from './components/layout';
import Colors from './components/colors';
import Typography from './components/foundations/typography';
import Home from './components/home';

function App() {

  function generateInvertedThemeCss() {
    const pairs = [
      ['--f-color-theme-primary-main', '--f-color-theme-primary-contrast'],
      ['--f-color-theme-primary-subtle-hover', '--f-color-theme-primary-subtle-hover-contrast'],
      ['--f-color-theme-primary-tint', '--f-color-theme-primary-tint-contrast']
      // Add other pairs you want to switch here.
    ];
  
    let cssContent = '.invert {\n';
  
    pairs.forEach(([var1, var2]) => {
      cssContent += `  ${var1}: var(${var2});\n`;
      cssContent += `  ${var2}: var(${var1});\n`;
    });
  
    cssContent += '}';
  
    const styleTag = document.createElement('style');
    styleTag.innerHTML = cssContent;
    document.head.appendChild(styleTag);
  }
  
  generateInvertedThemeCss();
  
  const path = window.location.pathname.split('/');
  const brand = path[1] || '0000';
  const docsBrand = '0001';
  // use live tokens if url contains docs.so5.dev
  let { tokens, docsTokens, tokensFile, docsTokensFile, cdn } = ''

  let local = window.location.hostname.includes('localhost');
  if (!local) {
    cdn = 'https://csscdn.herokuapp.com';
  } else {
    cdn = 'http://localhost:4000';
  }

  tokensFile = `${cdn}/${brand}/tokens.json`;
  docsTokensFile = `${cdn}/${docsBrand}/tokens.json`;


  tokens = useData(tokensFile);
  docsTokens = useData(docsTokensFile);


  if (docsTokens !== null) {
    let style = document.getElementById("docs-styles");
    let docsStylesheet = `${cdn}/${docsBrand}/styles-flat.css`;

    if (!style) {
      style = document.createElement('style');
      style.id = "docs-styles"
      document.head.appendChild(style);
    }
    style.innerHTML = `@import url(${docsStylesheet});`;
  }



    if (tokens !== null) {
      let style = document.getElementById("ca-styles");
      let stylesheet = `${cdn}/${brand}/styles.css`;

      if (!style) {
        style = document.createElement('style');
        style.id = "ca-styles"
        document.head.appendChild(style);
      }
      style.innerHTML = `@import url(${stylesheet});`;
    }


  if (tokens === null) {
    // Render a loading state or a fallback value
    return (
      <div className="w-screen h-screen animate-pulse">
      <header className='text-left bg-slate-700 p-6 text-lg'>&nbsp;</header>
      <div className='flex min-h-full'>
        <nav className='w-60 min-h-full px-1 border-r border-slate-200 bg-slate-50'>
        </nav>
      </div>
    </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/0000/" replace={true} />} />
        <Route path=":brand/" element={<Layout name={tokens.brand.displayName} />}>
          <Route path="" element={<Home data={tokens} />} />
          <Route path="foundations/colors" element={<Colors tokens={tokens?.color} />} />
          <Route path="foundations/typography" element={<Typography typographyData={tokens['type']} />} />
          <Route path="components/button" element={<Simple tokens={tokens?.button} />} />
          <Route path="components/badge" element={<Simple tokens={tokens?.badge} />} />
          <Route path="components/text-input" element={<Simple tokens={tokens['text-input']} />} />
          <Route path="components/textarea" element={<Simple tokens={tokens['textarea']} />} />
          <Route path="components/select" element={<Simple tokens={tokens['select']} />} />
          <Route path="components/accordion" element={<Simple tokens={tokens['accordion']} />} />
          <Route path="components/checkbox" element={<Simple tokens={tokens['checkbox']} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
