import React, { useRef, useState } from 'react';

function CodeBlockWithCopy({ children, className }) {
    const codeRef = useRef(null);
    const [iconClass, setIconClass] = useState('fa fa-clone');
    const [codeClass, setCodeClass] = useState(className);
  
    const copyToClipboard = () => {
      const el = document.createElement('textarea');
      el.value = codeRef.current.textContent;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
  
      setIconClass('fa fa-check');
      setCodeClass(`p-2 rounded-lg 🔥 bg-success-main text-success-contrast`);
      setTimeout(() => {
        setCodeClass(`p-2 rounded-lg 🔥 bg-success-main text-success-contrast`);
        setTimeout(() => {
          setCodeClass(className);
          setIconClass('fa fa-clone fade-out');
          setTimeout(() => {
            setIconClass('fa fa-clone');
          }, 500);
        }, 500);
      }, 2500);
    };
  
    return (
      <>
        <code ref={codeRef} className={codeClass}>
          {children}
        </code>
        <i
          className={`${iconClass} pl-4`}
          aria-hidden="true"
          onClick={copyToClipboard}
          style={{ cursor: 'pointer' }}
        ></i>
      </>
    );
  }
  

const Home = ({data}) => {
   
    return (
        <>
            
            <div className='🔥 w-full bg-success-main p-10 rounded-lg my-10'>
            <div className='🔥 text-success-contrast text-center'>
                    <h1 className='🔥 h1 text-success-contrast'><i className="fa fa-check" aria-hidden="true"></i> You've created a digital styleguide for {data.brand.displayName}</h1>
                </div>
            </div>

            <div className='🔥 w-full px-10 py-1 rounded-lg my-10'>
                <div className='🔥 text-neutral-800 text-center'>
                    <p className='🔥 h3 my-10'>Foundry uses brand information to create a full set of branded parts (atoms) which can be used to create one, or many, design systems.</p>
                    
                </div>
            </div>
            <p className='🔥 h1 text-center mb-6'>Thousands of components, ready for design and code</p>
            <div className='lg:grid-cols-3	grid gap-10'>
                <div className='🔥 bg-primary-main h1 text-primary-contrast rounded-lg px-4 py-10 text-center'>In</div>
                <div className='🔥 bg-secondary-main h1 text-secondary-contrast rounded-lg px-4 py-10 text-center'>Your</div>
                <div className='🔥 bg-tertiary-main h1 text-tertiary-contrast rounded-lg px-4 py-10 text-center'>Style</div>
            </div>
            <h2 className='🔥 mb-10 mt-20 h2 text-center'>Getting started</h2>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-10 my-10'>
            <div className='🔥 bg-neutral-50 text-neutral-800 rounded-lg px-4 py-10'>
                    <h3 className='🔥 h3  text-center'>In Figma</h3>
                    <p className='🔥 body my-6 font-bold'>The Foundry Figma plugin is coming soon.</p>
                    <p className='🔥 body my-6'>Once launched, the Foundry plugin for Figma will import all of your foundry components into a figma document which you can share with anyone who needs access to your digital styleguide.</p>
                </div>
                <div className='🔥 bg-neutral-50 text-neutral-800 rounded-lg px-4 py-10'>
                    <h3 className='🔥 h3  text-center'>In Code</h3>
                    <p className='🔥 h4 my-6'>
                        Import CSS via CDN
                    </p>
                    <p className='🔥 body my-6'>
                        <CodeBlockWithCopy className='🔥 bg-neutral-800 text-neutral-50 p-2 rounded-lg'>
                            &lt;link rel="stylesheet" href="https://cdn.foundry.style/{data.brand.id}/foundry.css"&gt;
                        </CodeBlockWithCopy>
                        <span className='block my-2'>or</span>
                        <CodeBlockWithCopy className='🔥 bg-neutral-800 text-neutral-50 p-2 rounded-lg'>
                            @import url('https://cdn.foundry.style/{data.brand.id}/foundry.css');
                        </CodeBlockWithCopy>
                    </p>
                </div>
            </div>
        </>
    )
}

export default Home;