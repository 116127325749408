import React, {useState} from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import Rebrand from './rebrand';

const Layout = ({name}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [disableScroll, setDisableScroll] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setDisableScroll(!disableScroll);

    if (disableScroll) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

const handleLinkClick = () => {
  if (window.innerWidth <= 1024) { // Assuming mobile devices have a width <= 1024
    toggleMenu();
  }
};

const NavLink = ({ to, label }) => {
  const { pathname } = useLocation();
  const isActive = pathname === to;

  return (
    <Link
      className={`block text-sm py-4 px-6 h-12 hover:bg-neutral-dark-subtle-hover w-full ${
        isActive ? 'font-bold' : ''
      }`}
      to={to}
      data-mdb-ripple="true"
      data-mdb-ripple-color="dark"
      onClick={handleLinkClick}
    >
      {label}
    </Link>
  );
};

const menuData = [
  {
    title: 'Foundations',
    items: [
      { path: 'foundations/colors', label: 'Colours' },
      { path: 'foundations/typography', label: 'Typography' },
    ],
  },
  {
    title: 'Components',
    items: [
      { path: 'components/button', label: 'Button' },
      { path: 'components/badge', label: 'Badge' },
      { path: 'components/text-input', label: 'Text input' },
      { path: 'components/textarea', label: 'Textarea' },
      { path: 'components/select', label: 'Dropdown (select)' },
      { path: 'components/accordion', label: 'Accordion (summary)' },
      { path: 'components/checkbox', label: 'Checkbox' },
    ],
  },
];

const renderMenuItem = (path, label, handleClick) => (
  <li>
    <NavLink to={path} label={label} onClick={handleClick} />
  </li>
);

const renderTitle = (title) => (
  <li className="grid text-left">
    <span className="p-4 🔥 text-neutral-light-contrast font-bold">{title}</span>
  </li>
);

const menuItems = (
  <ul className="">
    {menuData.map((section) => (
      <>
        {renderTitle(section.title)}
        {section.items.map((item) =>
          renderMenuItem(item.path, item.label, item.path === 'foundations/colors' ? handleLinkClick : null)
        )}
      </>
    ))}
  </ul>
);


  return (
    <div className="App h-screen 🔥 body">
<header className="🔥 bg-primary-main p-6 flex justify-between items-center lg:h-20 sticky top-0 z-0">
      <h1 className="🔥 text-primary-contrast font-bold h2">
      Styleguide: {name}
        </h1>
        <div className="hidden lg:block"><Rebrand /></div>
        
      </header>
      <div className='flex min-h-full'>
      <nav className="hidden lg:block w-60 min-h-full px-1 border-r border-neutral-200 bg-neutral-50">
          
          {menuItems}
        </nav>
        <div className='w-full h-full px-1 bg-white'>
          <div className='container mx-auto'>
            <div className='p-2 pt-4 lg:py-4 lg:px-8'>
              <Outlet />
            </div>
            <div className='py-4 px-8 text-center'>
                <span className="text-xl mr-2">🦘🇦🇺</span>Styleguide made in Sydney by <a href="https://alpha.foundry.style" className="🔥 🧩:btn 📐:small 🎨:neutral-light 👀:block ✋ inline-block" rel="noreferrer" target="_blank">🔥 foundry</a>
              </div>
          </div>
        </div>
      </div>
      <button
  className={`border-0 bg-transparent lg:hidden 🔥 text-primary-contrast focus:outline-none z-10 right-0 top-0 absolute m-4 ${menuOpen ? 'hidden' : 'block'}`}
  onClick={toggleMenu}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 6h16M4 12h16M4 18h16"
    />
  </svg>
</button>

{/* Mobile side navigation */}
<div
  className={`${
    menuOpen ? 'block' : 'hidden'
  } lg:hidden space-y-2 absolute 🔥 bg-slate-50 w-screen top-0 h-screen left-0 h-full bg-neutral-50`}
>
  <nav className="w-full min-h-full px-1 border-r border-slate-200">
    {/* Hamburger/Close icon for mobile */}
<button
  className="border-0 bg-transparent lg:hidden 🔥 text-neutral-800 focus:outline-none z-10 right-0 top-0 absolute m-4"
  onClick={toggleMenu}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`h-6 w-6 ${menuOpen ? "hidden" : ""}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 6h16M4 12h16M4 18h16"
    />
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`h-6 w-6 ${menuOpen ? "" : "hidden"}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
</button>

    {/* Side navigation content */}
    {menuItems}
  </nav>
</div>
    </div>

    
  );
};



export default Layout;
