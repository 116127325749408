import { useState } from "react";

function Rebrand() {

  const path = window.location.pathname.split('/');
  const brand = path[1] || '0000';

  const [enteredId, setEnteredId] = useState(brand);

  function handleInputChange(event) {
    setEnteredId(event.target.value);
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      // Redirect to /{enteredId}/rest/of/path
      window.location.pathname = `/${enteredId}/${path.slice(2).join('/')}`;
    }
    // only accept numbers, backspace, delete, and arrow keys
    if (event.key.match(/[^0-9]/) && !event.key.match(/(Backspace|Delete|Arrow)/)) {
      event.preventDefault();
    }
  }

  return (
    <div className="flex gap-0">
      <input
      className="🔥 🧩:text 📐:large 🎨:primary 👀:outline ✋ 🔄 p-2 text-xl tracking-widest text-center w-24 square-r"
      type="text"
      placeholder="####"
      value={enteredId}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown} />
      <button className="🔥 🧩:btn 📐:medium 🎨:primary 👀:block ✋ 🔄 square-l">Change brand</button></div>
  );
}

export default Rebrand;
