import { useEffect, useState } from 'react';

// const API_URL = 'https://ludicrous-sugar.surge.sh/variables.json';

// export async function fetchData() {
//   const response = await fetch(API_URL);
//   return response.json();
// }

// export function useData() {
//   const [tokens, setTokens] = useState(null);

//   useEffect(() => {
//     async function fetchAndSetData() {
//       const data = await fetchData();
//       setTokens(data);
//     }

//     fetchAndSetData();
//   }, []);

//   return tokens;
// }


export async function fetchData(url) {
  console.log(`Getting data from ${url}`);
  const response = await fetch(url);
  return response.json();
}

export function useData(url) {
  const [tokens, setTokens] = useState(null);

  useEffect(() => {
    async function fetchAndSetData() {
      const data = await fetchData(url);
      setTokens(data);
    }

    fetchAndSetData();
  }, [url]);

  return tokens;
}