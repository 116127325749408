import React, {useEffect} from 'react';
import SimpleVariants from './simple-variants';

const Simple = (props) => {

  const voidElements = ['input', 'br', 'hr', 'img', 'area', 'base', 'col', 'embed', 'param', 'source', 'track', 'wbr'];

  function isNotVoidElement(element) {
      return !voidElements.includes(element);
  }

  const { tokens } = props;

  function componentContent(str) {
    switch (str) {
      case 'select':
        return <><option>Option 1</option><option>Option 2</option><option>Option 3</option><option>Option 4</option></>;
      default:
        return tokens.metaData.displayName;
    }
  }

  useEffect(() => {
    // Do something with the updated tokens
    
  }, [tokens]);

  function printPerms(json, container) {
    if (json.metaData && json.metaData.type === "component") {
      let styles = json.metaData?.styles || ['block'];
      let sizes = json.metaData?.sizes || ['default'];
      let colors = json.metaData?.colors || ['disabled'];

      let childrenArr = [];
  
      // Loop through the styles array
      for (let color of colors) {
        let sectionTitle = <h3 className={`🔥 h3 capitalize mb-4 text-${color}-neutral`}>{color}</h3>;
        let section = React.createElement('div', { className: `🔥 m-4 bg-${color}-contrast p-4 rounded-md` });
        section = React.cloneElement(section, {}, section.props.children, sectionTitle);
        let sectionCanvas = React.createElement('div', {});
        let row = React.createElement('div', { className: `inline-grid grid-cols-${styles.length} gap-2` });
        // Loop through the sizes array
        for (let size of sizes) {
          
          // Loop through the colors array
          for (let style of styles) {
            
            let interactive = '';
            if (json.metaData?.states?.includes('hover')) {
              interactive = ' ✋';
            }
            let perm = React.createElement(
              json.metaData.element,
              {
                  ...json.metaData.elementAttributes,
                  className: `🔥 ${size !== 'default' ? `🧩:${json.metaData.cssName} 📐:${size}` : `🧩:${json.metaData.cssName}`} 👀:${style}${interactive} 🎨:${color}`
              },
              isNotVoidElement(json.metaData.element) ? componentContent(json.metaData.element) : null
          );
          
            // Add the permutation to a container
            const contained = React.createElement('div', { className: '' }, perm);
            // Add the container to the row
            row = React.cloneElement(row, {}, row.props.children, contained);
          }
        }
          // Add the row to the sectionCanvas
          sectionCanvas = React.cloneElement(sectionCanvas, {}, sectionCanvas.props.children, row);
          section = React.cloneElement(section, {}, section.props.children, sectionCanvas);
          childrenArr.push(section);
        
      } 
      //componentDiv.appendChild(document.createElement('hr'));
      //return componentDiv;
      return childrenArr.map((child) => React.createElement('div', { className: 'my-4' }, child));
    }
  } 

  const divs = printPerms(tokens);
  return (
    <div className='w-full'>
      <div className='text-slate-600 text-sm font-bold w-full text-left'>
      Styleguide &gt; Components &gt; {tokens.metaData.displayName}
      </div>
      <h1 className='🔥 h1 my-4'>{tokens.metaData.displayName}</h1>
      <p className='my-4'>{tokens.metaData.description}</p>
      <div className='🔥 bg-neutral-50 p-4 rounded-lg'>
        <SimpleVariants metaData={tokens.metaData} />
        <hr className='hidden lg:block' />
      <div className="lg:block hidden">
        {/* Render the array of divs using the map function */}
        <React.Fragment>
          {divs.map((div, index) => (
            <div className='inline-block' key={index}>{div}</div>
          ))}
        </React.Fragment>
      </div>
      </div>

    </div>
  );
};

export default Simple;