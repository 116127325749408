import React, { useState, useEffect } from 'react';
import Highlight from 'react-highlight';

const ColorVariants = ({ colors }) => {




    const [bg, setBg] = useState('main');
    const [palette, setPalette] = useState('neutral-dark');
    const [text, setText] = useState('contrast');
    const [border, setBorder] = useState('neutral');

    useEffect(() => {
        // Set the state values to their default values
        setPalette('neutral-dark');
        setBg('main');
        setText('contrast');
        setBorder('neutral');
    }, [colors]);

    const handleBgChange = (event) => {
        setBg(event.target.value);
    };

    const handlePaletteChange = (event) => {
        setPalette(event.target.value);
    };

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handleBorderChange = (event) => {
        setBorder(event.target.value);
    };

    const componentContent = <p>Hello world</p>;
    const componentContentStr = '<p>Hello world</p>';

    return (
        <div className={``}>
            <div className='flex'>
                <div className={`🔥 bg-${palette}-contrast text-center rounded-md h-80 flex items-center justify-center w-4/5`}>
                        {

                            React.createElement(
                                'div',
                                {
                                    className: `🔥 border-4 ${bg !== 'none' ? `bg-${palette}-${bg}` : ''} ${text !== 'none' ? `text-${palette}-${text}` : ''} ${border !== 'none' ? `border-${palette}-${border}` : 'border-transparent'} p-4 rounded-md`,
                                },
                                componentContent
                            )}
                </div>
                <div className='w-1/5 px-6'>
                <div className=''>
                <label htmlFor="color-select" className='block text-slate-800 text-sm font-bold mb-2'>Palette</label>
                <select
                    id="color-select"
                    value={palette}
                    onChange={handlePaletteChange}
                    className="🔥 🧩:select 🎨:neutral-dark 👀:form-highlight ✋">
                            {Object.keys(colors).map(palette => (
          <option key={palette} value={palette}>{palette}</option>
        ))}
                </select>
            </div>
            <div className='my-4'>
                <label htmlFor="bg-select" className='block text-slate-800 text-sm font-bold mb-2'>Background</label>
                <select
                    id="bg-select"
                    value={bg}
                    onChange={handleBgChange}
                    className="🔥 🧩:select 🎨:neutral-dark 👀:form-highlight ✋"
                >
                    <option value='none' key='none'>None</option>
                    <option value='main' key='main'>Main</option>
                    <option value='contrast' key='contrast'>Contrast</option>
                    <option value='tint' key='tint'>Tint</option>
                    <option value='subtle-hover' key='subtle-hover'>Subtle hover</option>
                    <option value='neutral' key='neutral'>Neutral</option>
                </select>
            </div>            <div className='my-4'>
                <label htmlFor="bg-select" className='block text-slate-800 text-sm font-bold mb-2'>Border</label>
                <select
                    id="border-select"
                    value={border}
                    onChange={handleBorderChange}
                    className="🔥 🧩:select 🎨:neutral-dark 👀:form-highlight ✋"
                >
                    <option value='none' key='none'>None</option>
                    <option value='main' key='main'>Main</option>
                    <option value='contrast' key='contrast'>Contrast</option>
                    <option value='tint' key='tint'>Tint</option>
                    <option value='subtle-hover' key='subtle-hover'>Subtle hover</option>
                    <option value='neutral' key='neutral'>Neutral</option>
                </select>
            </div>
            <div>
                <label htmlFor="text-select" className='block text-slate-800 text-sm font-bold mb-2'>Text</label>
                <select
                    id="text-select"
                    value={text}
                    onChange={handleTextChange}
                    className="🔥 🧩:select 🎨:neutral-dark 👀:form-highlight ✋ "
                >
                    <option value='main' key='main'>Main</option>
                    <option value='contrast' key='contrast'>Contrast</option>
                    <option value='tint' key='tint'>Tint</option>
                    <option value='subtle-hover' key='subtle-hover'>Subtle hover</option>
                    <option value='neutral' key='neutral'>Neutral</option>
                </select>
            </div>
                </div>
            </div>
            <div className='hljs w-full p-4 my-4 rounded-md'>
                <Highlight className='html'>
                    {`<div class="🔥 ${text !== 'none' ? `text-${palette}-${text}` : ''}${bg !== 'none' ? ` bg-${palette}-${bg}` : ''}${border !== 'none' ? ` border-${palette}-${border}` : ''}">
    ${componentContentStr}
</div>`}
                </Highlight>

            </div>

        </div>
    );
};
export default ColorVariants;

