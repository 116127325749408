import React from 'react';
import Highlight from 'react-highlight';

const quotes = {
  xs: [
    "I see dead people.",
    "Keep your friends close.",
    "Life is like a box of chocolates.",
    "To infinity, and beyond!",
    "I'll be back.",
    "Hello, McFly!",
    "I'm the Dude, man.",
    "I know kung fu.",
    "It's behind you, Tyrone!",
    "Don't panic.",
    "Inconceivable!",
    "E.T. Phone home.",
    "Always carry a towel.",
    "Shaken, not stirred.",
    "The Dude abides.",
    "Life finds a way.",
    "There is no spoon.",
    "You're the One, Neo.",
    "Great Scott!",
    "This is heavy.",
    "I am your father.",
    "Hey, nice marmot.",
  ],
  short: [
    "Shaken, not stirred.",
    "Life finds a way.",
    "We want cake. Cake and fine wine.",
    "So long, and thanks for all the fish.",
    "Roads? Where we're going, we don't need roads.",
    "May the force be with you.",
    "You're gonna need a bigger boat.",
    "I think we've been in here too long. I feel unusual.",
    "Do you know what nemesis means?",
    "Hey, nice marmot.",
    "That rug really tied the room together.",
    "Nobody calls me chicken.",
    "Yeah, well, you know, that's just, like, your opinion, man.",
    "We've gone on holiday by mistake!",
    "I'll make him an offer he can't refuse.",
    "You talking to me?",
    "Get busy living, or get busy dying.",
    "I have a very particular set of skills.",
    "Hasta la vista, baby.",
    "To infinity and beyond!",
    "So long, and thanks for all the fish.",
    "Always carry a towel.",
    "Roads? Where we're going, we don't need roads.",
    "May the force be with you.",
    "You're gonna need a bigger boat.",
    "I think we've been in here too long. I feel unusual.",
    "Do you know what nemesis means?",
    "That rug really tied the room together."
  ],
  long: [
    "You talking to me? You talking to me? You talking to me? Then who the hell else are you talking to? You talking to me? Well, I'm the only one here.",
    "Toto, I've got a feeling we're not in Kansas anymore. We must be over the rainbow!",
    "I love the smell of napalm in the morning. You know, one time we had a hill bombed, for twelve hours. When it was all over, I walked up. We didn't find one of 'em, not one stinkin' body. The smell, you know that gasoline smell, the whole hill. Smelled like... victory.",
    "This aggression will not stand, man! This will not stand, ya know, this aggression against Kuwait!",
    "Life moves pretty fast. If you don't stop and look around once in a while, you could miss it.",
    "My mama always said, 'Life was like a box of chocolates. You never know what you're gonna get.'",
    "I feel the need... the need for speed!",
    "A million dollars isn't cool. You know what's cool? A billion dollars.",
    "You know, this place makes me wonder. Which would be worse, to live as a monster or to die as a good man?",
    "In this world, Elwood, you must be oh, so smart or oh, so pleasant. Well, for years I was smart. I recommend pleasant.",
    "As far back as I can remember, I always wanted to be a gangster.",
    "Oh, no, it wasn't the airplanes. It was Beauty killed the Beast."
  ],
};

const Typography = ({ typographyData }) => {
  const renderElement = (key, value) => {
    const commonClasses = `🔥 ${key} my-4`;
    let quoteType;
    if (key === 'body') {
      quoteType = 'long';
    } else if (key === 'h1') {
      quoteType = 'xs';
    } else {
      quoteType = 'short';
    }
    if (key === 'body') {
      return (
        <><p className={`lg:w-96 ${commonClasses}`}>
          {quotes[quoteType][Math.floor(Math.random() * quotes[quoteType].length)]}
        </p><p className={`lg:w-96 ${commonClasses}`}>
            {quotes[quoteType][Math.floor(Math.random() * quotes[quoteType].length)]}
          </p></>
      );
    } else {
      return React.createElement(
        key,
        { className: `${commonClasses}` },
        quotes[quoteType][Math.floor(Math.random() * quotes[quoteType].length)]
      );
    }
  };

  return (
    <div className='w-full'>
      <div className='text-slate-600 text-sm font-bold w-full text-left'>
        Styleguide &gt; Foundations &gt; Typography
      </div>
      <h1 className='🔥 h1 my-4'>Typography styles</h1>
      <p className='my-4'>
        Here you'll find the typography styles we use, which you can also see in Figma. Check out the examples, code snippets, and utility classes for easy implementation.
      </p>
      <div className="flex flex-col inline-flex mt-16">
        {Object.entries(typographyData.headings).map(([key, value]) => {
          // check the entry is an object and skip if not
          if (typeof value !== 'object') return null;
  
          const tag = key === 'body' ? 'p' : key;
  
          return (
            <div key={key} className="mb-8 w-full">
              <div className="🔥 bg-neutral-50 p-4 rounded-md">
                <h2 className="text-2xl font-bold mb-4">{key.toUpperCase()}</h2>
                <div className='flex flex-col lg:flex-row gap-4 '>
                  <div className="lg:grow">
                    <div className={`🔥 bg-white p-4 lg:pr-10 rounded-md`}>
                      {renderElement(key, tag)}
                    </div>
                    <div className="hljs w-full p-4 mt-4 rounded-md hidden lg:block">
                      <Highlight className='html'>
                        {`<${tag} class="🔥 ${key}">${quotes['xs'][Math.floor(Math.random() * quotes['xs'].length)]}</${tag}>`}
                      </Highlight>
                    </div>
                  </div>
                  <div className="w-60">
                    <ul className="reset pl-6">
                      <li><strong>Size:</strong> {value.fontSize}px</li>
                      <li><strong>Line Height:</strong> {value.lineHeight}px</li>
                      <li><strong>Font:</strong> {value.fontFamily}</li>
                      <li><strong>Font Weight:</strong> {value.fontWeight}</li>
                      <li><strong>Color:</strong> {value.color}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
  ;
}

export default Typography;
