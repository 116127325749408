import React from 'react';
import Palette from './palette';
import ColorVariants from './components/color-variants';

function Colors(props) {
    const { tokens } = props;
    const finalColors = { ...tokens.theme, "neutral": {...tokens.utility.neutral}};

  return (
    <div className='w-full'>
      <div className='text-slate-600 text-sm font-bold w-full text-left'>
        Styleguide &gt; Foundations &gt; Colours
      </div>
      <h1 className='🔥 h1 my-4'>Colours</h1>
    <div className='flex lg:gap-8 flex-col lg:flex-row'>
    <div className='lg:w-1/2'>
    <p className='my-4'>Superset comes with colours available as paint styles in figma, and as utility classes in CSS.</p>
    <p className='my-4'>Colours are organised into palettes, which are used for branding colours as well as function colours, like success or warning.</p>
        </div>
        <div className='lg:w-1/2'>
        <p className='my-4'>Our colours use functional shades. In each palette there is:</p>
        <ul>
        <li><b>Main</b> - The colour itself</li>
        <li><b>Contrast</b> - A colour you can use against <em>Main</em> (usually black or white)</li>
        <li><b>Tint</b> - A tinted version of main which keeps contrasts like <em>Main</em>, useful for hover states.</li>
        <li><b>Subtle hover</b> - A colour to replace transparent in hover states</li>
        <li><b>Neutral Alt</b> - A colour which is neutral, but contrasts to others like <em>Main</em> (usually black or white)</li>
        </ul>
        </div>

    </div>
        <h3 className='🔥 h3 my-4 hidden lg:block'>Colour utility classes</h3>
        <div className='bg-slate-200 p-4 rounded-lg hidden lg:block'>
        <ColorVariants colors={tokens.theme} />
        </div>
      <h3 className='🔥 h3 my-4'>Colours in this theme</h3>
      <Palette colors={finalColors} />
      </div>
  );
}

export default Colors;
